<!-- eslint-disable @angular-eslint/template/eqeqeq -->
<div class="content-offline">
  <div fxLayoutAlign="center center" class="actions-btns">
    <div fxLayout="column" fxLayoutAlign="center center">
      <button
        mat-mini-fab
        color="primary"
        class="btn-filter"
        aria-label="Filtros"
        *ngIf="this.selectedTabIndex == 1 && numberFilters > 0"
      >
        <div *ngIf="numberFilters > 0" class="badge-filter">
          {{ this.selectedTabIndex }}
        </div>

        <mat-icon class="filter-icon" matTooltip="Filtros">filter_alt</mat-icon>
      </button>
    </div>
  </div>
</div>

<mat-tab-group
  mat-align-tabs="start"
  [selectedIndex]="selectedTabIndex"
  (selectedTabChange)="tabChanged($event)"
  style="height: 100%"
>
  <mat-tab label="Lista" aria-label="Lista">
    <div fxLayout="column">
      <div fxLayout="row">
        <div [fxFlex]="isOfflineMode ? 75 : 90">
          <mat-form-field class="full-medium-width">
            <mat-label>
              <mat-icon class="icon-label">search</mat-icon>
              &nbsp;&nbsp;Buscar cliente
            </mat-label>

            <input
              type="text"
              class="input-search"
              color="white"
              placeholder="Busca por nombre o descripción"
              matInput
              [(ngModel)]="searchCustomer"
              (ngModelChange)="onSearchCustomerChange($event)"
              autocomplete="off"
            />
          </mat-form-field>
        </div>

        <div *ngIf="isOfflineMode" fxFlex="15" fxLayoutAlign="center center">
          <div
            mat-mini-fab
            color="primary"
            class="btn-search"
            (click)="this.fetchClients({ isTpmFilter: this.isTpmFilter }, true)"
          >
            <mat-icon class="icon-search">search</mat-icon>
          </div>
        </div>

        <div fxFlex="5" class="button-filter">
          <div
            mat-mini-fab
            color="primary"
            (click)="openNavSelectFilterEvent.emit()"
          >
            <div *ngIf="numberFilters > 0" class="badge-filter">
              {{ numberFilters }}
            </div>

            <mat-icon class="filter-icon">filter_alt</mat-icon>
          </div>
        </div>
      </div>
    </div>

    <div fxLayout="column" class="uploader-status"></div>
    <section
      class="content-clients"
      infiniteScroll
      (scrolled)="onScrollDownClients()"
      [infiniteScrollDistance]="distance"
      [scrollWindow]="scrollDisabled"
      *ngIf="clients.length > 0"
    >
      <div
        fxLayout="column"
        *ngFor="let client of clients; let i = index; trackBy: trackByClientId"
      >
        <mat-accordion class="accordion-headers-align" multi>
          <mat-expansion-panel
            (opened)="getPolls(client.cliente_id, client.empresa_id, i, false)"
          >
            <mat-expansion-panel-header>
              <mat-panel-title class="panelTitle">
                <mat-icon class="client-all-icon client-all-icon-account_circle"
                  >perm_contact_calendar</mat-icon
                >

                <span class="row-client">
                  {{ client.descr_cliente }} - {{ client.cliente_id }}
                  <br />
                  {{ client.direccion }}
                  <br />
                  <span class="datePoll">Código: {{ client.cliente_id }}</span>
                </span>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <ng-container *ngIf="hasActivePolls; else noPollsMessage">
              <mat-action-row *ngIf="showTab">
                <div class="tasks-icon" (click)="handleCreateTask(client)">
                  <mat-icon>task_alt</mat-icon>
                </div>
                <div>
                  <span (click)="findIndicators(client)" class="tasks-icon">
                    <mat-icon>analytics</mat-icon>
                  </span>
                </div>
                <div>
                  <span class="tasks-icon" (click)="openMaps(client)">
                    <mat-icon>location_on</mat-icon>
                  </span>
                </div>
              </mat-action-row>
              <mat-card
                *ngFor="let poll of client.tmpPolls"
                class="mat-elevation-z0"
                style="margin-top: 5px"
              >
                <div fxLayout="row">
                  <div fxFlex="10">
                    <div
                      fxLayout="column"
                      fxLayoutAlign="center center"
                      class="full-height"
                    >
                      <mat-icon class="client-icon">ballot</mat-icon>
                      <div
                        *ngIf="
                          isOfflineMode &&
                          (poll.edit == 'trueOffline' || poll.edit == 'false')
                        "
                        class="text-small-bold"
                      >
                        <mat-icon>wifi_off</mat-icon>
                      </div>

                      <div *ngIf="poll.edit == 'true'" class="text-small-bold">
                        <mat-icon>wifi</mat-icon>
                      </div>
                    </div>
                  </div>

                  <div fxFlex="45">
                    <div fxFlex="column" style="padding-left: 5px">
                      <div>
                        {{ poll.name }}
                        <br />
                      </div>

                      <div>
                        <span class="text-small-bold"
                          >Expira:
                          {{ poll.expire_date | date : 'dd MMM yyyy' }}</span
                        >
                      </div>
                    </div>
                  </div>

                  <div
                    *ngIf="
                      isOfflineMode &&
                      (poll.edit == 'trueOffline' || poll.edit == 'true')
                    "
                    class="content-completed"
                  >
                    <div>
                      <mat-icon class="task-icon">task</mat-icon>
                    </div>
                  </div>

                  <div
                    *ngIf="isOfflineMode && poll.edit == 'false'"
                    class="content-completed"
                  >
                    <div>
                      <mat-icon
                        matTooltip="Encuesta no completada"
                        class="pending-actions"
                        >pending_actions</mat-icon
                      >
                    </div>
                  </div>

                  <div
                    fxFlex="15"
                    fxLayoutAlign="center center"
                    class="actions-btns"
                  >
                    <button
                      *ngIf="poll.edit == 'true' || poll.edit == 'trueOffline'"
                      mat-flat-button
                      class="btn-option"
                      aria-label="Editar encuesta"
                      (click)="
                        isOfflineMode
                          ? verifyInitPoll(poll, client)
                          : editPoll(poll, client)
                      "
                    >
                      <mat-icon matTooltip="Editar" class="edit-icon"
                        >create</mat-icon
                      >
                    </button>
                  </div>

                  <div
                    fxFlex="15"
                    fxLayoutAlign="center center"
                    class="actions-btns"
                  >
                    <button
                      *ngIf="!isOfflineMode && poll.edit == 'true'"
                      class="btn-option"
                      mat-flat-button
                      class="btn-option"
                      aria-label="Reiniciar encuesta"
                      (click)="
                        restartPoll(
                          client.descr_cliente,
                          client.cliente_id,
                          client.empresa_id,
                          poll.id,
                          poll.filter_by_user,
                          i
                        )
                      "
                    >
                      <mat-icon matTooltip="Reiniciar">cached</mat-icon>
                    </button>
                  </div>

                  <div
                    fxFlex="15"
                    fxLayoutAlign="center center"
                    class="actions-btns"
                  >
                    <div
                      *ngIf="
                        isPollEdit(poll.edit) == 'false' ||
                        isPollEdit(poll.edit) == false
                      "
                      color="white"
                      aria-label="Seleccionar"
                      class="btn-action ml-4"
                      (click)="verifyInitPoll(poll, client)"
                    >
                      <mat-icon class="action-icon">navigate_next</mat-icon>
                    </div>
                  </div>
                </div>
              </mat-card>
            </ng-container>

            <ng-template #noPollsMessage>
              <mat-card class="mat-elevation-z0" style="margin-top: 5px">
                Sin encuestas activas.
              </mat-card>
            </ng-template>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div *ngIf="isLoading" class="spinner-container">
        <mat-spinner diameter="60"></mat-spinner>
      </div>
    </section>

    <div fxLayout="column">
      <div fxLayoutAlign="center center">
        <button
          class="scrollToTopBtn"
          *ngIf="showButtonTop"
          (click)="onScrollTop()"
        >
          Subir
        </button>
      </div>
    </div>
  </mat-tab>
  >

  <mat-tab label="Mapa" aria-label="Mapa" *ngIf="showTab">
    <div fxLayout="column">
      <app-independent-maps
        [centerPositionSubject]="centerPositionSubject"
        (pollSelected)="initPoll($event, 8)"
      >
      </app-independent-maps>
    </div>
  </mat-tab>
</mat-tab-group>

<div
  class="indicators-background"
  *ngIf="showIndicators"
  (click)="hideIndicators()"
>
  <div class="indicators-container">
    <div class="indicators-title">
      <h2 class="indicators-text">Indicadores disponibles:</h2>

      <button class="close-button" (click)="hideIndicators()">
        <mat-icon aria-label="Cerrar">close</mat-icon>
      </button>
    </div>

    <ul class="indicators-list">
      <li class="indicators-content" *ngIf="!customerIndicators.length">
        <div class="alert-message">
          <mat-icon class="alert-icon">warning</mat-icon>
          <p>Este cliente no tiene indicadores para visualizar.</p>
        </div>
      </li>

      <li
        class="indicators-content"
        *ngFor="let data of customerIndicators; let i = index"
      >
        <img class="indicator-icon" src="assets/icons/indicator_icon.png" />

        <a class="indicator-link" href="{{ data.url }}" target="_blank">
          {{ data.name }}</a
        >
        <mat-icon class="lunch-icon">launch</mat-icon>
      </li>
    </ul>
  </div>
</div>

<ngx-spinner
  name="loading-clients-all"
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="rgb(92, 184, 92)"
  type="ball-spin-fade"
>
  <p style="font-size: 16px; color: rgb(255, 255, 255)">Cargando clientes...</p>
</ngx-spinner>

<ngx-spinner
  name="synchronizingEditPoll"
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="rgb(92, 184, 92)"
  type="ball-clip-rotate-pulse"
  class="content-spinner"
>
  <div class="progress-upload">
    <div style="margin-top: 134px"></div>
    <mat-progress-bar
      mode="determinate"
      [value]="progressUploadPollValue"
      class="sync-progress-bar"
    ></mat-progress-bar>
    <div style="margin-top: 16px; color: rgb(92, 184, 92); width: 205px">
      {{ progressUploadPollText }}
      <span>{{ progressUploadPollValue }}%</span>
    </div>
  </div>
</ngx-spinner>

<ngx-spinner
  name="client-poll-spinner"
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="rgb(92, 184, 92)"
  type="ball-spin-fade"
>
  <p
    style="
      font-size: 18px;
      color: rgb(255, 255, 255);
      padding-top: 30px;
      text-align: center;
    "
  >
    Cargando encuesta de
  </p>

  <p
    style="
      font-size: 14px;
      color: rgb(255, 255, 255);
      text-align: center;
      white-space: nowrap;
    "
  >
    {{ clientSelected?.descr_cliente }}
  </p>
</ngx-spinner>

<ngx-spinner
  name="loading-indicators"
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="rgb(92, 184, 92)"
  type="ball-spin-fade"
>
  <p
    style="
      font-size: 16px;
      color: rgb(255, 255, 255);
      padding-top: 30px;
      text-align: center;
    "
  >
    Cargando indicadores...
  </p>
</ngx-spinner>
