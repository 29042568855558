import { HostListener, Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { BehaviorSubject, Observable, from, of, timer } from 'rxjs';
import {
  catchError,
  concatMap,
  filter,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import { auth } from 'src/environments/environment';
import NoSleep from 'nosleep.js';
import { OfflineService } from './offline.service';
import { ClientsService } from './clients.service';
import { StatsService } from './stats.service';
import { formatDate } from '@angular/common';
import * as jwt_decode from 'jwt-decode';
import { UserModel } from '../models/user.model';
import { UserService } from './user.service';
import { EnterpriseModel } from '../models/enterprise';
import { NavigationExtras, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { NavbarComponent } from 'src/app/shared/components/v2';
import { SpinnerService } from './spinner.service';
import Swal from 'sweetalert2';
import { LogsService } from 'src/app/polls/services/logs.service';
import { BrowserModule } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class SyncService {
  private nosleep = new NoSleep();
  private API_URL: string = auth.urlConfig;
  user: UserModel;
  lastVerificationDateTimeForClient: string;
  lastVerificationDateTimeForStructure: string;
  private enterprises: EnterpriseModel[] = [];
  private navigationExtras: NavigationExtras = {
    state: {
      isReconfiguration: true,
    },
  };

  progressText: string = 'Descargando Clientes';
  progressValue: number = 0;

  private storageSubject = new BehaviorSubject<boolean>(true);
  public $storageSubject: Observable<boolean> =
    this.storageSubject.asObservable();

  constructor(
    private http: HttpClient,
    private clientsService: ClientsService,
    private offlineService: OfflineService,
    private statsService: StatsService,
    private userService: UserService,
    private router: Router,
    private authService: AuthService,
    private spinnerService: SpinnerService,
    private readonly logsService: LogsService
  ) {
    this.user = jwt_decode(localStorage.getItem('token')).user;
  }

  @HostListener('window:online', ['$event'])
  handleOnlineEvent() {
    this.initVerification().subscribe();
    this.enterprises = [];
  }
  initVerification(isFirstTimeLoginDay: boolean = false): Observable<boolean> {
    if (isFirstTimeLoginDay) {
      this.showSpinner();
    }
    return from(
      this.offlineService
        .getOfflineHeader(1, this.user.id)
        .then((offlineHeader) => {
          this.lastVerificationDateTimeForClient =
            offlineHeader?.lastVerificationDateTimeForClient;
          this.lastVerificationDateTimeForStructure =
            offlineHeader?.lastVerificationDateTimeForStructure;

          const dateTimeDiffWithDB = parseInt(
            localStorage.getItem('dateTimeDiffWithDB') || '0',
            10
          );
          return (
            !this.lastVerificationDateTimeForClient ||
            new Date(this.lastVerificationDateTimeForClient).getTime() +
              3600000 <
              Date.now() + dateTimeDiffWithDB ||
            !this.lastVerificationDateTimeForStructure ||
            new Date(this.lastVerificationDateTimeForStructure).getTime() +
              3600000 <
              Date.now() + dateTimeDiffWithDB
          );
        })
    ).pipe(
      switchMap((hasToVerify) => {
        if (hasToVerify) {
          return this.verifyChanges();
        } else {
          return new Observable<boolean>((subscriber) => {
            subscriber.next(false);
            subscriber.complete();
          });
        }
      })
    );
  }

  private verifyChanges(
    isFirstTimeLoginDay: boolean = false
  ): Observable<boolean> {
    let params = new HttpParams();
    params = params.set('sellers', '[]');
    params = params.set(
      'lastVerificationDateTimeForClient',
      this.lastVerificationDateTimeForClient
    );
    params = params.set(
      'lastVerificationDateTimeForStructure',
      this.lastVerificationDateTimeForStructure
    );
    return this.http.get<any>(`${this.API_URL}/checks/all`, { params }).pipe(
      tap(async (response) => {
        const keysWithTrueValue = Object.keys(response).filter(
          (key) => response[key] === true
        );
        const shouldSyncClients =
          keysWithTrueValue.includes('checkClients') &&
          new Date(response.checkClientsLastUpdate).getTime() >
            new Date(this.lastVerificationDateTimeForClient).getTime();

        if (keysWithTrueValue.length) {
          if (
            keysWithTrueValue.includes('checkSaleStructure') &&
            new Date(response.checkSaleStructureLastUpdate).getTime() >
              new Date(this.lastVerificationDateTimeForStructure).getTime()
          ) {
            // 1ro traer el salesStructure actualizado en indexedDB y pisar el anterior en localStorage
            this.updateUserSalesData();

            await this.offlineService.clearOfflineHeader();
            localStorage.setItem('reconfiguration', 'true');

            // 2do redirigir a poll para obligar al usuario a que sincronice con la nueva saleStructure
            this.router.navigate(['/poll'], this.navigationExtras);
          } else if (shouldSyncClients) {
            this.syncChanges(isFirstTimeLoginDay);
          }
        }
      }),
      filter((response) => response.changed)
    );
  }

  updateUserSalesData() {
    this.userService.getInfoSalesData('unit').subscribe((data) => {
      let i = 0;
      localStorage.setItem('sales', JSON.stringify(data));

      data.forEach((usersale) => {
        i++;

        if (
          this.enterprises.findIndex(
            (usersaleItem) => usersaleItem.id == usersale.enterprise.id
          ) == -1
        ) {
          this.enterprises.push(usersale.enterprise);
        }

        if (i >= data.length) {
          localStorage.setItem('enterprises', JSON.stringify(this.enterprises));
          this.authService.setShowTabByEnterpriseSubject(true);
        }
      });
    });
  }

  async syncChanges(isFirstTimeLoginDay: boolean = false) {
    this.clientsService
      .getClientsAndPollsBySellers([])
      .subscribe(async (data) => {
        try {
          if (isFirstTimeLoginDay) {
            this.hideSpinner();
          }
          const hash = Math.random().toString(36).substring(7);
          this.sendLog(hash, 'start');
          this.spinnerService.showSpinner(false, 'Sincronizando datos...', {
            progressText: 'Iniciando',
            progressValue: 0,
          });
          const clientsLimit = data.clients;

          this.spinnerService.updateProgress({
            progressText: 'Descargando SKUs',
            progressValue: 10,
          });

          const enterprises = JSON.parse(
            localStorage.getItem('enterprises')
          )?.map((e) => e.id);
          const customerSku = await this.clientsService
            .getAllSkus(enterprises)
            .toPromise();
          await this.offlineService.setCustomersSku(
            customerSku['customersSku']
          );

          this.spinnerService.updateProgress({
            progressText: 'Descargando Clientes',
            progressValue: 20,
          });

          const batchSize = 1000;

          await this.offlineService.setClientsInBatches(
            clientsLimit,
            batchSize,
            (progress) => {
              const adjustedProgress = Math.round(30 + progress * 0.2);
              this.spinnerService.updateProgress({
                progressValue: adjustedProgress,
                progressText: 'Descargando Clientes',
              });
            }
          );

          this.spinnerService.updateProgress({
            progressValue: 60,
            progressText: 'Descargando Polls',
          });

          let polls: any = await this.offlineService.getSectionsPoll(
            data.polls
          );

          await this.offlineService.setPolls(polls);

          this.spinnerService.updateProgress({
            progressValue: 70,
            progressText: 'Descargando Categorias',
          });

          await this.offlineService.downloadCategories([]);

          await this.offlineService.clearPollStats();

          this.spinnerService.updateProgress({
            progressValue: 90,
            progressText: 'Descargando Estadisticas',
          });

          const clientPolls = data.polls;

          for (const poll of clientPolls) {
            const statsData = await this.statsService
              .getStatsByPollPeriodGoals(poll.id, [])
              .pipe(
                map((stats) => ({
                  ...stats,
                  user_id: this.user.id,
                  id: `${poll.id}-${this.user.id}`,
                }))
              )
              .toPromise();
            await this.offlineService.setPollStats(statsData);
          }

          await this.offlineService.setOfflineHeader({
            id: 1,
            user_id: this.user.id,
            date: formatDate(new Date(), 'yyyy/MM/dd', 'en'),
            number_clients: data.clients.length,
            number_polls: polls.length,
            status: true,
            mode: 'syncAll',
            from: 'clients',
            sellers: [],
            dataFilters: {
              enterpriseId: null,
              filters: [],
              isFilter: false,
              keepFilter: false,
            },
            dataFiltersPoll: [],
            lastVerificationDateTimeForClient: new Date(
              Date.now() +
                parseInt(localStorage.getItem('dateTimeDiffWithDB') || '0', 10)
            ).toISOString(),
          });

          this.spinnerService.updateProgress({
            progressValue: 100,
            progressText: 'Completado',
          });
          this.spinnerService.hideSpinner();
          this.sendLog(hash, 'end');

          this.progressText = '';
          this.progressValue = 0;
        } catch (error) {
          this.handleHttpError(error);
        }
      });
  }

  handleHttpError(error: HttpErrorResponse): void {
    if (error.status === 0) {
      // Error de red o sin conexión a Internet
      Swal.fire({
        icon: 'error',
        title: 'Sin conexión',
        text: 'Debe tener conexión a internet para sincronizar.',
      }).then((result) => {
        if (result.isConfirmed) {
          this.hideSpinner();
          this.router.navigate(['/poll']);
        }
      });
    } else {
      // Error del lado del servidor
      let mensaje = 'Ocurrió un error, se intentará sincronizar nuevamente.';

      if (error.status >= 500) {
        mensaje = 'Error en el servidor. Por favor, inténtalo más tarde.';
      }

      Swal.fire({
        icon: 'error',
        title: `Oops...`,
        text: mensaje,
      }).then((result) => {
        if (result.isConfirmed) {
          this.hideSpinner();
          this.router.navigate(['/poll']);
        }
      });
    }
  }

  showSpinner() {
    this.spinnerService.showSpinner(false, 'Cargando...');
  }

  hideSpinner() {
    this.spinnerService.hideSpinner();
  }

  async sendLog(hash, status) {
    let offlineHeader = await this.offlineService.getOfflineHeader(
      1,
      this.user.id
    );

    offlineHeader?.token && delete offlineHeader.token;
    const logData = {
      event_type: 'sync_auto',
      payload: {
        status,
        hash,
        offlineHeader,
      },
    };
    this.logsService.sendLog(logData).subscribe({
      next: () => {},
      error: () => {},
    });
  }
}
