import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import Dexie from 'dexie';
import 'dexie-export-import';
import { AppDB } from 'src/app/db/db';

@Injectable({
  providedIn: 'root',
})
export class IndexedDBService {
  private db: AppDB;

  constructor(@Inject(DOCUMENT) private readonly document: Document) {
    this.db = new AppDB();
  }

  async exportDatabase() {
    try {
      const blob = await this.db.export();
      const url = URL.createObjectURL(blob);
      const a = this.document.createElement('a');
      a.href = url;
      a.download = `${this.db.name}_backup.json`;
      this.document.body.appendChild(a);
      a.click();
      this.document.body.removeChild(a);
      URL.revokeObjectURL(url);
      console.log('Exportación completada');
    } catch (error) {
      console.error('Error exportando la base de datos:', error);
    }
  }

  async importDatabase(file: File) {
    try {
      // Eliminar la base de datos antes de la importación
      await this.db.delete();
      this.db = new AppDB(); // Reinstanciar la base de datos
      const arrayBuffer = await file.arrayBuffer();
      const blob = new Blob([arrayBuffer], { type: 'application/json' });
      await this.db.import(blob);
      console.log('Importación completada');
    } catch (error) {
      console.error('Error importando la base de datos:', error);
    }
  }
}
