import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { auth } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LogsService {
  private readonly API_URL = `${auth.urlConfig}/logs`;

  constructor(private readonly httpClient: HttpClient) {}

  sendLog(data: {
    poll_id?: number;
    client_id?: string;
    event_type: string;
    payload?: {};
  }): Observable<any> {
    return this.httpClient
      .post<any>(this.API_URL, data)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: any) {
    console.error('An error occurred:', error);
    return throwError('Something went wrong; please try again later.');
  }
}
