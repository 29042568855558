import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { Subject } from 'rxjs';
import { QuestionModel } from 'src/app/polls/models/question.model';
import { ResponseService } from 'src/app/polls/services/response.service';
import { OfflineService } from 'src/app/polls/services/offline.service';
// Dexie DB
import { db } from 'src/app/db/db';
import { UserModel } from 'src/app/polls/models/user.model';
import { PollModel } from 'src/app/polls/models/poll.model';
import * as jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.css'],
})
export class TextComponent implements OnChanges {
  @Input() idResponse: number;
  @Input() question: QuestionModel;
  @Input() selected: any;
  @Output() optionSelected = new EventEmitter();
  @Output() showHideSpiner = new EventEmitter();
  maxChars = 255;
  response = '';
  chars = 0;
  responseQuestionSubject: Subject<string> = new Subject<string>();
  offlineMode: any = {
    status: false,
    from: 'nonde',
  };
  user: UserModel;
  poll: PollModel;

  constructor(
    private responseService: ResponseService,
    private offlineService: OfflineService
  ) {
    this.user = jwt_decode(localStorage.getItem('token')).user;
    this.poll = JSON.parse(localStorage.getItem('poll'));
  }

  ngOnChanges(): void {
    this.response = '';
    this.chars = 0;
    if (this.selected == null) {
      this.getResponse();
    }
  }

  onTextResponseChange(textContent: string) {
    this.optionSelected.emit(textContent);
  }

  getResponse() {
    this.offlineService
      .getOfflineHeader(1, this.user.id)
      .then((offlineHeader) => {
        if (offlineHeader) {
          this.offlineMode = offlineHeader;
        }
        // Init Offline Mode
        if (this.offlineMode.status && this.offlineMode.from === 'clients') {
          const client = JSON.parse(localStorage.getItem('client'));
          const id = `${this.question.id}-${this.poll.id}-${client.id}-${this.user.id}`;
          this.offlineService
            .getQuestionResponseDB(id)
            .then(async (questionData) => {
              if (questionData) {
                this.response = questionData.data.text;
                if (this.response?.length > 0) {
                  this.optionSelected.emit(this.response);
                }
              }
              this.showHideSpiner.emit('hide');
            });
          return;
        }
        // End Offline Mode
        this.showHideSpiner.emit('show');
        this.responseService
          .getResponseQuestion({
            response_id: this.idResponse,
            question_id: this.question.id,
          })
          .subscribe((data) => {
            if (data.length > 0) {
              this.response = data[0].textvalue
                ? data[0].textvalue
                : data[0].value;

              if (this.response.length > 0) {
                this.optionSelected.emit(this.response);
              }
            } else {
              this.optionSelected.emit(null);
              this.response = null;
            }
            this.showHideSpiner.emit('hide');
          });
      });
    const textArea = document.getElementsByClassName('text-area-class')[0];
    const textAreaHeight = window.getComputedStyle(textArea).height;
    const newHeight = parseFloat(textAreaHeight.split('px')[0]) * 1.3;
    textArea.setAttribute('style', `height: ${newHeight}px`);
  }

  refreshOptions(data: QuestionModel) {
    this.getResponse();
  }
}
