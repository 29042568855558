import Swal from 'sweetalert2';
import { forkJoin, of } from 'rxjs';
import * as moment from 'moment';
import * as jwt_decode from 'jwt-decode';
import { catchError, finalize, first, tap } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import {
  ETaskPeriod,
  ITaskCompleted,
  Question,
  Task,
} from '../../entities/task.entity';
import { TasksService } from '../../services/tasks.service';
import {
  IGetResponseTask,
  IGetTaskTemplates,
  IQuestionAnswer,
} from '../../interfaces';
import { TaskResponseDTO, TaskTemplatesDTO } from '../../dtos';
import { UploadService } from 'src/app/polls/services/upload.service';

@Component({
  selector: 'app-tasks-create',
  templateUrl: './tasks-create.component.html',
  styleUrls: ['./tasks-create.component.css'],
})
export class TasksCreateComponent implements OnInit {
  public selectedTemplate: Task;
  public templates: Task[] = [];
  public client: any;
  public questionPosition: number = 0;
  public responses: IQuestionAnswer[] = [];
  public currentLocation = {};
  public currentQuestion: Question;
  public user;
  public allowMoveQuestion: boolean = false;
  public responseInfo: ITaskCompleted;
  private location: any;
  public limitDate: Date = null;
  public showDate: boolean = false;

  public isSuccessCreatedTask: boolean = true;
  public canOpenModal: boolean = true;

  constructor(
    private readonly tasksService: TasksService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly spinner: NgxSpinnerService,
    private readonly uploadService: UploadService
  ) {
    this.user = jwt_decode(localStorage.getItem('token')).user;
    this.location = JSON.parse(localStorage.getItem('currenLocation'));
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      const clientFromStorage = localStorage.getItem(
        `create_task_${params.id}`
      );

      if (!clientFromStorage) this.router.navigate(['/poll']);

      this.client = JSON.parse(clientFromStorage);
      const location = localStorage.getItem('currenLocation');
      this.currentLocation = location ? JSON.parse(location) : {};
    });

    this.fetchTaskTemplate();
  }

  fetchTaskTemplate() {
    this.spinner.show('find-tasks-spinner');

    const params: IGetTaskTemplates = {
      enterpriseId: this.client.empresa_id,
      clientId: this.client.cliente_id,
      orderZoneSale: this.client.orden_zona_venta,
    };

    this.tasksService
      .getTaskTemplates(params)
      .subscribe((res: TaskTemplatesDTO) => {
        this.templates = res.uens.map((temp) => {
          const questions = temp.questions.filter(
            (q) => q.type_task === 'create' || q.type_id === 9
          );

          return { ...temp, questions };
        });

        this.spinner.hide('find-tasks-spinner');
      });
  }

  handleBack() {
    this.questionPosition -= 1;

    if (!this.selectedTemplate) {
      this.router.navigate(['/poll']);
    } else if (this.questionPosition < 0) {
      this.selectedTemplate = null;
      this.currentQuestion = null;
      this.responses = [];
    }

    this._setCurrentQuestion();
  }

  handleNext() {
    if (!this.selectedTemplate) return;

    if (this.showDate && this.limitDate) {
      if (this.questionPosition === this.selectedTemplate.questions.length) {
        this.handleFinish();
      }
      this.showDate = false;
    } else if (
      this.questionPosition ===
      this.selectedTemplate.questions.length - 1
    ) {
      this.handleFinish();
    } else {
      this.questionPosition += 1;
    }

    this._setCurrentQuestion();
  }

  handleFinish() {
    this.fetchResponse();
  }

  handleTextAnswer(event: any) {
    if (!event?.length) {
      this.allowMoveQuestion = false;
    } else {
      this.allowMoveQuestion = true;
      const questionAnswered = this._buildResponse(event);
      this._pushResponse(questionAnswered);
    }
  }

  handleDateAnswer(event: any) {
    if (moment(event).isBefore(moment(new Date()).subtract(1, 'd'))) {
      Swal.fire({
        title: 'Error',
        text: 'La fecha limite NO puede ser una fecha previa a la actual.',
        icon: 'error',
      });

      this.allowMoveQuestion = false;
      this.limitDate = null;

      return;
    }

    this.allowMoveQuestion = true;
    this.limitDate = event;
  }

  handlePhotoAnswer(event: any) {
    this.allowMoveQuestion = true;
    const questionAnswered = this._buildResponse(event);
    this._pushResponse(questionAnswered);
  }

  _buildResponse(answer) {
    const question = this.selectedTemplate.questions[this.questionPosition];
    const response = { answer };

    return { ...question, response };
  }

  _pushResponse(response) {
    const responseExist = this.responses.find((res) => res.id === response.id);

    if (!responseExist) this.responses.push(response);
    else {
      this.responses = this.responses.map((res) => {
        if (res.id === response.id) return response;

        return res;
      });
    }
  }

  async _saveResponses() {
    const requests = [];

    this.responses.forEach((response: IQuestionAnswer) => {
      if (response.type_id === 4) {
        response.response.answer.forEach((image) => {
          this.uploadService
            .pushFileBase64ToStorage(image.image, image.fileName, image.token)
            .pipe(
              catchError(() => {
                this.isSuccessCreatedTask = false;
                return of();
              }),
              finalize(() => {
                if (!this.isSuccessCreatedTask) {
                  this._cleanContext();
                }
              })
            )
            .subscribe(() => {});

          const body = {
            question_id: response.options[0].question_task_id,
            option_id: response.options[0].id,
            type_id: response.type_id,
            value: `${image.token}/${image.fileName}`,
          };

          requests.push(body);
        });
      } else {
        const body = {
          question_id: response.options[0].question_task_id,
          option_id: response.options[0].id,
          type_id: response.type_id,
          value: response.response.answer,
        };

        requests.push(body);
      }
    });

    const responses = requests.map((request) => {
      const id: number = this.responseInfo.id;

      return this.tasksService.saveResponse(id, request);
    });

    this.spinner.show('upload-response-spinner');

    forkJoin(responses)
      .pipe(
        catchError(() => {
          this.isSuccessCreatedTask = false;
          return of();
        }),
        finalize(() => {
          this._cleanContext();
        })
      )
      .subscribe();
  }

  handleSelectTemplate(template) {
    this.selectedTemplate = template;
    this.questionPosition = 0;
    this.showDate = !this.selectedTemplate.isDeadlineQuestion;

    if (!this.limitDate && !this.selectedTemplate.isDeadlineQuestion) {
      this.showDate = true;

      return;
    }

    this._setCurrentQuestion();
  }

  _setCurrentQuestion() {
    this.currentQuestion =
      this.selectedTemplate?.questions[this.questionPosition];

    if (
      !this.currentQuestion &&
      !this.selectedTemplate?.questions[this.questionPosition + 1]
    )
      return;

    const existResponse = this.responses.find(
      (res) => res.id === this.currentQuestion.id
    );

    if (existResponse) {
      this.allowMoveQuestion = true;
      this.currentQuestion = existResponse;

      return;
    }

    this.allowMoveQuestion = false;
  }

  fetchResponse() {
    this.spinner.show('upload-response-spinner');

    if (this.selectedTemplate.isDeadlineQuestion) {
      const { amount, period } = this.selectedTemplate;
      this.limitDate = moment(new Date())
        .add(amount, ETaskPeriod[period])
        .toDate();
    }

    const params: IGetResponseTask = {
      customer_id: this.client.id,
      task_id: this.selectedTemplate.id,
      cliente_id: this.client.cliente_id,
      empresa_id: this.client.empresa_id,
      lat: this.location.lat,
      long: this.location.lng,
      limit_date: this.limitDate,
    };

    this.tasksService
      .getResponse(params)
      .pipe(
        catchError(() => {
          this.isSuccessCreatedTask = false;
          return of();
        }),
        finalize(() => this._saveResponses())
      )
      .subscribe((res: TaskResponseDTO) => {
        this.responseInfo = res.createdTask;
      });
  }

  _cleanContext() {
    if (!this.canOpenModal) return;
    this.canOpenModal = false;
    this.spinner.hide('upload-response-spinner');
    Swal.fire({
      title: this.isSuccessCreatedTask
        ? 'Tarea creada con éxito.'
        : 'Error al crear tarea.',
      text: this.isSuccessCreatedTask
        ? '¿Quieres crear otra tarea para el mismo cliente?'
        : '¿Quieres intentar crear la tarea con el mismo cliente?',
      icon: this.isSuccessCreatedTask ? 'success' : 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'Salir',
    }).then(async (result) => {
      if (result.isConfirmed) {
        this.canOpenModal = true;
        this.allowMoveQuestion = false;
        this.selectedTemplate = null;
        this.currentQuestion = null;
        this.questionPosition = 0;
        this.responses = [];
        this.responseInfo = null;
        this.limitDate = null;
      } else if (result.isConfirmed === false) {
        this.router.navigate(['/poll']);
      }
    });
  }
}
