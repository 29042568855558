import {
  Component,
  ElementRef,
  EventEmitter,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  ApplicationRef,
  OnInit,
  Input,
} from '@angular/core';
import * as jwt_decode from 'jwt-decode';
import { Categories } from '../../clients/clients.component';
import { ClientsService } from '../../../services/clients.service';
import { OfflineService } from '../../../services/offline.service';
import { UserModel } from '../../../models/user.model';
import { FormControl } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

import {
  MatListOption,
  MatSelectionList,
  MatSelectionListChange,
} from '@angular/material/list';
import { Observable, from, of } from 'rxjs';
import { catchError, concatMap, map, startWith } from 'rxjs/operators';
import { Category } from '../../../models/category.models';
import Swal from 'sweetalert2';
import { formatDate } from '@angular/common';
import { StatsService } from 'src/app/polls/services/stats.service';
import { Network } from '@ngx-pwa/offline';

// No Sleep
import NoSleep from 'nosleep.js';
import { seller } from '../../../../../environments/environment.develop';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { LogsService } from 'src/app/polls/services/logs.service';
import { IndexedDBService } from 'src/app/services/indexeddb.service';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-select-route',
  templateUrl: './select-route.component.html',
  styleUrls: ['./select-route.component.scss'],
})
export class SelectRouteComponent implements OnInit {
  @Input() reconfiguration: boolean = false;
  @Output() closeNavSelectRouteEvent = new EventEmitter();
  @Output() setListOptionsVendedor = new EventEmitter();

  @Output() progressTextChange = new EventEmitter<string>();
  @Output() progressChange = new EventEmitter<number>();
  @Output() progressSpinnerChange = new EventEmitter<boolean>();

  @ViewChild('vendedorInput') vendedorInput: ElementRef;
  @ViewChild('VendedorTarget') VendedorTarget: MatSelectionList;
  @ViewChildren('optionsVendedores')
  optionsVendedores: QueryList<MatListOption>;
  user: UserModel;
  private nosleep = new NoSleep();
  vendedorCtrl = new FormControl();
  filteredVendedores: Observable<string[]>;
  allVendedor: Category[] = [];
  searchPend: string = '';
  progressText: string = 'Descargando Clientes';
  progressValue: number = 0;
  showExportButton: boolean = false;
  isUserAllowedParallelSync: boolean = false;
  //test multiples peticiones:
  currentUserEmail: string = this.offlineService.user.email;
  quantityParallelSync: number = 1;

  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  itemsFilter: any[] = [];
  sellers: any[] = [];
  sellersList: any[] = [];
  compareFunction = (o1: any, o2: any) =>
    o1.code?.toLowerCase() === o2.code?.toLowerCase();
  listOptionsVendedor: Category[] = [];
  selectedOptionsVendedor: Category[] = [];
  selectOption: boolean = true;
  selectAll: boolean = false;
  selectBySelle: boolean = false;

  selected: [] = [];

  firstTime: boolean = true;

  syncOptionSelected: boolean | undefined;
  syncOptions = [
    { value: false, viewValue: 'Sincronizar todos clientes' },
    { value: true, viewValue: 'Sincronizar por vendedor' },
  ];

  online$ = this.network.onlineChanges;
  isOnline: boolean = true;

  constructor(
    private clientsService: ClientsService,
    private offlineService: OfflineService,
    private statsService: StatsService,
    private analytics: AngularFireAnalytics,
    private appRef: ApplicationRef,
    protected network: Network,
    private router: Router,
    private readonly logsService: LogsService,
    private readonly authService: AuthService,

    private indexedDBService: IndexedDBService
  ) {
    this.filteredVendedores = this.vendedorCtrl.valueChanges.pipe(
      startWith(null),
      map((nivel: string | null) =>
        nivel ? this._filterVendedor(nivel) : this.allVendedor.slice()
      )
    );
    this.fetchCategoryTargetFilter([]);
    this.user = jwt_decode(localStorage.getItem('token')).user;

    this.offlineService
      .getOfflineHeader(1, this.user.id)
      .then(async (offlineHeader) => {
        this.firstTime = offlineHeader ? false : true;
      });
  }

  ngOnInit() {
    if (this.firstTime) {
      this.offlineService.loadOfflineHeader().then(() => {
        this.offlineService
          .getOfflineHeader(1, this.user.id)
          .then((offlineHeader) => {
            if (offlineHeader?.sellers?.length > 0) {
              this.selectedOptionsVendedor = offlineHeader.sellers;
              this.listOptionsVendedor = offlineHeader.sellers;
              this.syncOptionSelected = true;
            }
            if (offlineHeader?.sellers?.length === 0) {
              this.syncOptionSelected = false;
            }
          });
      });
    }
    this.isOnline = localStorage.getItem('statusConnection') === 'true';
    this.showExportButton = this.authService.hasFeatureFlag(
      'INDEX_DB_EXPORT_BUTTON'
    );
    this.isUserAllowedParallelSync =
      this.authService.hasFeatureFlag('PARALLEL_SYNC_TEST');
  }

  selectAllVendedor(event) {
    this.syncOptionSelected = event.value;
  }

  closeNav({ deleteSelected = false }) {
    this.closeNavSelectRouteEvent.emit({ deleteSelected });
  }

  showSelectOption() {
    this.selectAll = false;
    this.selectBySelle = false;
    this.selectOption = true;
  }

  showSelectAll() {
    this.selectBySelle = false;
    this.selectOption = false;
    this.selectAll = true;
  }

  showSelectBySelle() {
    this.listOptionsVendedor = [];
    this.selectedOptionsVendedor = [];
    this.selectOption = false;
    this.selectAll = false;
    this.selectBySelle = true;
  }

  async fetchCategoryTargetFilter(categories: Categories[]) {
    // Init Offline Mode
    // this.spinner.show('loading');
    // if (this.isOfflineMode) {
    // }
    // End Offline Mode
    this.clientsService.getCustomerCategories('ven').subscribe((sellers) => {
      localStorage.setItem(
        'dateTimeDiffWithDB',
        (Date.now() - new Date(sellers.currentDateDB).getTime()).toString()
      );
      this.sellers = sellers;
      this.sellersList = sellers.values;
      this.loadSellersTargetFilter(sellers);
    });
  }

  onListSelectionChange(ob: MatSelectionListChange) {
    const valueItemFilter = ob.options[0]['_value'];
    const index = this.listOptionsVendedor.findIndex(
      (lo) => lo.code?.toLowerCase() === valueItemFilter.code?.toLowerCase()
    );
    index === -1
      ? this.listOptionsVendedor.push(valueItemFilter)
      : this.listOptionsVendedor.splice(index, 1);
    setTimeout(() => {
      this.selectedOptionsVendedor = this.listOptionsVendedor;
    }, 100);
  }

  loadSellersTargetFilter(sellers) {
    sellers.values.length > 0 ? (this.allVendedor = sellers.values) : [];
  }

  private _filterVendedor(value: any): any[] {
    let searchValue = '';

    typeof value === 'string'
      ? (searchValue = value)
      : (searchValue = value.name);
    return this.allVendedor.filter((vendedor) => {
      return (
        vendedor.name?.toLowerCase().includes(searchValue?.toLowerCase()) ||
        vendedor.code?.toLowerCase().includes(searchValue?.toLowerCase())
      );
    });
  }

  searchOnFilterSellers(stringSearch) {
    this.allVendedor = this.sellersList.filter((vendedor) => {
      return (
        vendedor.name?.toLowerCase().includes(stringSearch?.toLowerCase()) ||
        vendedor.code?.toLowerCase().includes(stringSearch?.toLowerCase())
      );
    });
    setTimeout(() => {
      this.selectedOptionsVendedor = this.listOptionsVendedor;
    }, 100);
  }

  syncAll() {
    this.listOptionsVendedor = [];
    this.selectedOptionsVendedor = [];
    this.applySellersSelected(true);
  }

  runParallelSync(flag: boolean = true) {
    const syncCalls = Array.from({ length: this.quantityParallelSync }, () =>
      this.applySellersSelected(flag)
    );

    forkJoin(syncCalls).subscribe(
      (results) => {
        console.log('All sync operations completed successfully', results);
      },
      (error) => {
        console.error('Error in one of the sync operations', error);
      }
    );
  }

  async applySellersSelected(syncAll = false) {
    try {
      if (this.listOptionsVendedor.length === 0 && syncAll === false) {
        Swal.fire(
          'Seleccionar vendedor',
          'Por favor seleccione al menos un vendedor',
          'error'
        );
        return;
      }

      this.nosleep.enable();
      this.progressSpinnerChange.emit(true);
      this.appRef.tick();

      const data = await this.clientsService
        .getClientsAndPollsBySellers(this.listOptionsVendedor)
        .toPromise();

      this.analytics.logEvent(`activacion_sin_conexion`, {
        usuario: this.user.email,
        method: this.listOptionsVendedor.length === 0 ? 'all' : 'filter',
      });

      const hash = Math.random().toString(36).substring(7);
      this.sendLog(hash, 'start');

      const clientsLimit = data.clients;

      this.updateProgress(10, 'Descargando SKUs');

      const enterprises = JSON.parse(localStorage.getItem('enterprises'))?.map(
        (e) => e.id
      );

      const customerSkuData = await this.clientsService
        .getAllSkus(enterprises)
        .toPromise();

      await this.offlineService.setCustomersSku(
        customerSkuData['customersSku']
      );

      this.updateProgress(20, 'Descargando Clientes');

      const batchSize = 1000;

      await this.offlineService.setClientsInBatches(
        clientsLimit,
        batchSize,
        (progress) => {
          const adjustedProgress = Math.round(30 + progress * 0.2);
          this.updateProgress(adjustedProgress, 'Descargando Clientes');
        }
      );

      this.updateProgress(60, 'Descargando Polls');

      let polls: [] = [];
      try {
        const polls = await this.offlineService.getSectionsPoll(data.polls);
        await this.offlineService.setPolls(polls);
      } catch (error) {
        console.error('Error al descargar encuestas 2:', error);
        polls = [];
      }

      this.updateProgress(70, 'Descargando Categorias');

      await this.offlineService.downloadCategories(this.listOptionsVendedor);

      this.updateProgress(80, 'Descargando Categorias');

      await this.offlineService.clearPollStats();

      this.updateProgress(90, 'Descargando Estadisticas');

      const clientPolls = data.polls;

      for (const poll of clientPolls) {
        const statsData = await this.statsService
          .getStatsByPollPeriodGoals(poll.id, this.listOptionsVendedor)
          .pipe(
            map((stats) => ({
              ...stats,
              user_id: this.user.id,
              id: `${poll.id}-${this.user.id}`,
            }))
          )
          .toPromise();
        await this.offlineService.setPollStats(statsData);
      }

      await this.offlineService.setOfflineHeader({
        id: 1,
        user_id: this.user.id,
        date: formatDate(new Date(), 'yyyy/MM/dd', 'en'),
        number_clients: data.clients.length,
        number_polls: polls.length,
        status: true,
        mode: syncAll ? 'syncAll' : 'sellers',
        from: 'clients',
        sellers: this.listOptionsVendedor,
        dataFilters: {
          enterpriseId: null,
          filters: [],
          isFilter: false,
          keepFilter: false,
        },
        dataFiltersPoll: [],
        lastVerificationDateTimeForClient: data.currentDateDB,
        lastVerificationDateTimeForStructure: data.currentDateDB,
      });

      localStorage.setItem('reconfiguration', 'false');
      this.setListOptionsVendedor.emit(this.listOptionsVendedor);
      this.updateProgress(100, 'Completado');
      this.sendLog(hash, 'end');
      this.progressSpinnerChange.emit(false);
      this.listOptionsVendedor = [];
      this.selectedOptionsVendedor = [];
      this.showSelectOption();
      this.nosleep.disable();
      this.closeNav({ deleteSelected: true });
      this.updateProgress(0);

      setTimeout(() => {
        this.appRef.tick();
      }, 500);
    } catch (error) {
      this.progressSpinnerChange.emit(false);
      this.handleHttpError(error);
    }
  }

  updateProgress(value: number, text?: string) {
    this.progressValue = value;
    if (text) {
      this.progressText = text;
      this.progressTextChange.emit(this.progressText);
    }
    this.progressChange.emit(this.progressValue);
  }

  handleHttpError(error: HttpErrorResponse): void {
    if (error) {
      // Error del lado del servidor
      let mensaje = 'Ocurrió un error, intenta sincronizar nuevamente.';

      if (error.status >= 500) {
        mensaje = 'Error en el servidor. Por favor, inténtalo más tarde.';
      }

      Swal.fire({
        icon: 'error',
        title: `Oops...`,
        text: mensaje,
      }).then((result) => {
        if (result.isConfirmed) {
          this.progressSpinnerChange.emit(false);
          this.router.navigate(['/poll']);
        }
      });
    }
  }

  async sendLog(hash, status) {
    let offlineHeader = await this.offlineService.getOfflineHeader(
      1,
      this.user.id
    );

    offlineHeader?.token && delete offlineHeader.token;

    const logData = {
      event_type: 'sync_manual',
      payload: {
        status,
        hash,
        offlineHeader,
      },
    };

    this.logsService
      .sendLog(logData)
      .subscribe({ next: () => {}, error: () => {} });
  }

  async exportDatabase() {
    await this.indexedDBService.exportDatabase();
  }

  async importDatabase(event: any) {
    const file = event.target.files[0];
    if (file) {
      await this.indexedDBService.importDatabase(file);
    }
  }
}
